const general = [
    {
      brief: 'Copyright infringement claim',
      prompt: 'How can we address a copyright infringement claim from a competitor in Singapore?',
      value: 'How can we address a copyright infringement claim from a competitor in Singapore?',
    },
    {
      brief: 'Workplace injury incident',
      prompt: 'Can the injured employee pursue legal action for a workplace injury in Singapore?',
      value: 'Can the injured employee pursue legal action for a workplace injury in Singapore?',
    },
    {
      brief: 'Product safety concerns',
      prompt: 'What are the legal obligations for addressing product safety complaints in Singapore?',
      value: 'What are the legal obligations for addressing product safety complaints in Singapore?',
    },
    {
      brief: 'Partnership agreement negotiation',
      prompt: 'What legal considerations apply to a partnership agreement negotiation in Singapore?',
      value: 'What legal considerations apply to a partnership agreement negotiation in Singapore?',
    },
];

const marriage = [
  {
    brief: 'Marriage License Requirements',
    prompt: 'What are the requirements for obtaining a marriage license in Singapore?',
    value: 'What are the requirements for obtaining a marriage license in Singapore?',
  },
  {
    brief: 'Prenuptial Agreements',
    prompt: 'Are prenuptial agreements legally binding in this jurisdiction in Singapore?',
    value: 'Are prenuptial agreements legally binding in this jurisdiction in Singapore?',
  },
  {
    brief: 'Marriage Ceremony Options',
    prompt: 'What are the different types of marriage ceremonies available in Singapore?',
    value: 'What are the different types of marriage ceremonies available in Singapore?',
  },
  {
    brief: 'Marriage Certificate Replacement',
    prompt: 'How can I get a replacement for a lost marriage certificate in Singapore?',
    value: 'How can I get a replacement for a lost marriage certificate in Singapore?',
  },
];

const property = [
  {
    brief: 'Real Estate Transactions',
    prompt: 'What are the legal steps involved in a real estate transaction in Singapore?',
    value: 'What are the legal steps involved in a real estate transaction in Singapore?',
  },
  {
    brief: 'Property Disputes',
    prompt: 'How can property disputes be resolved through legal means in Singapore?',
    value: 'How can property disputes be resolved through legal means in Singapore?',
  },
  {
    brief: 'Property Taxes',
    prompt: 'What are the property tax rates in this area in Singapore?',
    value: 'What are the property tax rates in this area in Singapore?',
  },
  {
    brief: 'Buying vs. Renting',
    prompt: 'What are the pros and cons of buying vs. renting a property in Singapore?',
    value: 'What are the pros and cons of buying vs. renting a property in Singapore?',
  },
];

const immigration = [
  {
    brief: 'Visa Application Process',
    prompt: 'What is the process for applying for a visa to this country in Singapore?',
    value: 'What is the process for applying for a visa to this country in Singapore?',
  },
  {
    brief: 'Green Card Eligibility',
    prompt: 'Who is eligible for a green card in Singapore?',
    value: 'Who is eligible for a green card in Singapore?',
  },
  {
    brief: 'Work Visas',
    prompt: 'How can I obtain a work visa to work in Singapore?',
    value: 'How can I obtain a work visa to work in Singapore?',
  },
  {
    brief: 'Deportation Process',
    prompt: 'What is the process for deportation and how can it be challenged in Singapore?',
    value: 'What is the process for deportation and how can it be challenged in Singapore?',
  },
];

export const templates = {'General': general, 'Marriage': marriage, 'Property': property, 'Immigration': immigration}