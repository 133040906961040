import React from 'react';
import { Button, Collapse, Divider } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function ClauseGallery(props) {
  const clauses = props.clauses;
  const items = clauses.map((clause, index) => {
    const metadata = clause.metadata;
    const regexPattern = `^Title: ${escapeRegExp(metadata.title)}\n# ${escapeRegExp(metadata.part)}\n## ${escapeRegExp(metadata.division)}\n### ${escapeRegExp(metadata.provision)}`;
    const regex = new RegExp(regexPattern, 'm');
    const formattedDoc = clause.page_content.replace(regex, '');
    let content = "***" + metadata.title + '*** \n\n'
    if (metadata.part) {
      content += '*' + metadata.part;
    }
    if (metadata.division) {
      content += ' - ' + metadata.division
    }
    if (metadata.provision) {
      content += ' - ' + metadata.provision
    }
    content += '*' + formattedDoc;
    return {
      key: (index + 1).toString(),
      label: `Clause ${index + 1}: ${clause.metadata.provision}`,
      children: <ReactMarkdown children={content} />,
    }
  });
  return (
    <>
    <Divider orientation="left" style={{fontStyle: 'italic'}}>Relevant Clauses</Divider>
    <Collapse bordered={false} items={items}></Collapse>
    </>
  );
}

export default ClauseGallery;



