import React from 'react'
import SuggestionCard from './SuggestionCard'
import "./ReasoningPreset.css"

const ReasoningPreset = (props) => {
  return (
    <div className="reasoning-preset-container">
            <SuggestionCard
                handleClick={props.handleClick}
                className="reasoning-suggestion"
                brief="Contract Dispute: Digilandmall's Pricing Mistake"
                value="The defendant, Digilandmall.com Pte Ltd, were an online IT company that sold related software and hardware from Singapore. They were selling a HP laser printer and an employee accidently made a mistake as to the price of the printer on their website. It was listed at the price of $66, when it was advertised on the official HP website for $3,854. This was not noticed by the company until over 4,000 printers were ordered. The complainants had ordered over 100 printers each at this price. When the defendants discovered this mistake on their website, they sent an email to the complainants to say they would not be fulfilling this order. The complainants argued that they were not aware that this price was a mistake and wanted the binding contract to be fulfilled. The defendants argued this pricing was a unilateral mistake and that the complainants took advantage of this. The issue in this case was whether the pricing was a mistake and if the contract would be fulfilled?"/>
            <SuggestionCard
                handleClick={props.handleClick}
                className="reasoning-suggestion"
                brief="Contract Breach: Dudcom's Delivery Delay"
                value="Dudcom is a supplier of computer equipment. The Network Computer School (NCS) decided to purchase some computers, as replacements for existing ones, for student use on their premises. NCS begins a new teaching term every March and September. Therefore, in May 2013, NCS agreed to purchase 100 computers from Dudcom on, inter alia, the following terms:
                (i) The computers must have processing speeds of at least 2.8Ghz;
                (ii) The computers must have been assembled in Japan;
                (iii) Delivery of computers to be made by 1 September 2013;
                (iv) Furthermore, “It is a condition of this contract that the said computers shall bedelivered in brand-new condition”.
                Suppose the computers are not delivered by 1 September 2013. Despite several reminders by NCS they are still undelivered by 14 September 2013.
                "/>
        </div>
  )
}

export default ReasoningPreset