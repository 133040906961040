import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import {ReactComponent as TempConsultantSvg} from "../assets/icons/TempConsultantSvg.svg"
import {ReactComponent as TempRetrievalSvg} from "../assets/icons/TempRetrievalSvg.svg"
import {ReactComponent as TempDraftingSvg} from "../assets/icons/TempDraftingSvg.svg"
import { ReactComponent as ReasoningSvg } from '../assets/icons/ReasoningSvg.svg';
import {ReactComponent as AnalysingSvg} from "../assets/icons/AnalysingSvg.svg";
import { Icon } from '../utils/Icon';
import "./Sidebar.css";

const Sidebar = () => {
    const menuItem = [
        {
            path:"/consultant",
            name:"Consultant",
            icon:<Icon icon={TempConsultantSvg}/>
        },
        {
            path:"/retrieval",
            name:"Retrieval",
            icon:<Icon icon={TempRetrievalSvg}/>
        },
        {
            path:"/reasoning",
            name:"Reasoning",
            icon:<Icon icon={ReasoningSvg}/>
        },
        {
            path:"/drafting",
            name:"Drafting",
            icon:<Icon icon={TempDraftingSvg}/>
        },
        {
            path:"/analysing",
            name:"Analysing",
            icon:<Icon icon={AnalysingSvg}/>
        }
    ]
    return(
        <>
            <div className='container'>
                <div className='sider'>
                    {
                        menuItem.map((item, index)=>(
                            <NavLink to={item.path} key={index} style={({isActive})=>({
                                color: isActive? '#A72841':'#1B333F',
                                background:isActive?'#FFF':''
                                })} className="sider-item">
                                <div className='sub_item'>
                                <div className='icon'>{item.icon}</div>
                                <div className='item_text'>{item.name}</div>
                                </div>
                            </NavLink>
                        ))
                    }
                </div>
            </div>
            <Outlet/>
        </>
    )
}
export default Sidebar;