import React from 'react';
import {ReactComponent as TempLegalSvg} from "../assets/icons/TempLegalSvg.svg"
import { Icon } from '../utils/Icon';
import './Header.css';

function AppHeader() {
  return (
    <>
    <div className='header-container'>
      <div className='header'>
        <div className='header-item'>
          <Icon className='header-icon' fill="#ffffff" icon={TempLegalSvg}/>
          <div className='header-text'>legalExpert</div>
        </div>
      </div>
    </div>
    </>
  );
}
export default AppHeader;