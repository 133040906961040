import React from 'react';
import SuggestionCard from './SuggestionCard';
import "./Landing.css";

function Landing(props) {
    return (
    <div className="landing-container">
        <div className="grid-container">
            {props.suggestions.map((suggestion, index) => (
                <SuggestionCard
                    key={index}
                    handleClick={props.handleClick}
                    className="suggestion"
                    brief={suggestion.brief}
                    prompt={suggestion.prompt}
                    value={suggestion.value}
                />
            ))}
        </div>
    </div>
  );
};

export default Landing;
