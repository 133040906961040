import React from 'react'
import "./SubHeader.css"

const SubHeader = () => {
  return (
    <div className='subHeader-container'>
        {/* <div className='subHeader-text'>Legal Expert</div> */}
    </div>
  )
}

export default SubHeader