import React from 'react';
import { Card } from 'antd';
import "./SuggestionCard.css"
const { Meta } = Card;

function SuggestionCard(props) {
  return (
      <Card hoverable size='small' onClick={() => props.handleClick(props.value)}>
        <Meta title={props.brief} description={<div className='description-body'>{props.value}</div>} style={{textAlign: 'left'}}/>
      </Card>
  );
};

export default SuggestionCard;
