import React, { useState, useRef, useEffect } from 'react';
import { Markmap } from 'markmap-view';
import { loadCSS, loadJS } from 'markmap-common';
import { Transformer } from 'markmap-lib';
import { Button, Input } from 'antd'
import "./MarkmapComponent.css";
import { deriveOptions } from 'markmap-view';

import { Toolbar } from 'markmap-toolbar';
import 'markmap-toolbar/dist/style.css';

import { Icon } from '../utils/Icon';
import {ReactComponent as FullScreenSvg} from "../assets/icons/FullScreenSvg.svg";

export const transformer = new Transformer();
const { scripts, styles } = transformer.getAssets();
loadCSS(styles);
loadJS(scripts);

const {TextArea} = Input

const MarkmapComponent = ({markdown, setMarkdown}) => {
    // const [value, setValue] = useState(markdown);
    // Ref for SVG element
    const refSvg = useRef();
    // Ref for markmap object
    const refMm = useRef();
    // Ref for toolbar wrapper
    const refToolbar = useRef();

    // Set up wrapText
    const jsonOptions = {
      "maxWidth": 300
    }
    const markmapOptions = deriveOptions(jsonOptions);

    // useEffect(() => {
    //   setValue(markdown) 
    // })

    useEffect(() => {
      // Create markmap and save to refMm
      if (refMm.current) return;
      const mm = Markmap.create(refSvg.current, markmapOptions);
      console.log('create', refSvg.current);
      refMm.current = mm;
      renderToolbar(refMm.current, refToolbar.current);
    }, [refSvg.current]);

    useEffect(() => {
      // Update data for markmap once value is changed
      const mm = refMm.current;
      if (!mm) return;
      const { root } = transformer.transform(markdown);
      mm.setData(root);
      mm.fit();
      if (markdown) {
        renderToolbar(mm, refToolbar.current);
      }
    }, [markdown]);

    const handleChange = (e) => {
      setMarkdown(e.target.value);
    };

    // Fullscreen functionality
    const handleFullscreen = () => {
      if (refSvg.current) {
        if (!document.fullscreenElement) {
          refSvg.current.requestFullscreen().catch(err => {
            alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
          });
        } else {
          document.exitFullscreen();
        }
      }
    };

    // Render Toolbar
    function renderToolbar(mm, wrapper) {
      while (wrapper && wrapper.firstChild) {
        wrapper.firstChild.remove();
      }
      if (mm && wrapper) {
        const toolbar = new Toolbar();
        toolbar.setBrand(false); // remove the markmap brand sign
        toolbar.attach(mm);
        console.log("toolbar")
        console.log(toolbar)
        // Register custom buttons
        toolbar.register({
          id: 'fullScreen',
          title: 'Enter fullscreen',
          content: {
            props: {
              children: {
                props: {
                  d:'M20 3h2v6h-2V5h-4V3h4zM4 3h4v2H4v4H2V3h2zm16 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z',
                  fill:'currentColor',
                  stroke:'none',
                },
                type:'path',
                vtype: 1
              },
              height:"10",
              viewBox:"0 0 24 24",
              width:"10"
            },
            type:'svg',
            vtype:1
          },
          // content: <Icon icon={FullScreenSvg}/>,
          onClick:handleFullscreen,
          // onClick: function() {
          //   // alert('You made it!');
          //   handleFullscreen
          // },
        });
        toolbar.setItems(Toolbar.defaultItems.concat('fullScreen'));
        wrapper.appendChild(toolbar.render());
      }
    }

    return (
      <React.Fragment>
        
        
        <svg className={markdown == ""? "empty-mindmap":"mindmap"} ref={refSvg} />
        <div className="flex-1">
          {/* <TextArea
            style={{resize:'none'}}
            className='markdown-input'
            value={markdown}
            onChange={handleChange}
            rows={8}
          /> */}
        </div>
        {markdown && (<div className="markmap-toolbar" ref={refToolbar}></div>)}
        {/* <div className="markmap-toolbar" ref={refToolbar}></div> */}
      </React.Fragment>
    );
}

export default MarkmapComponent;