import React, { useState } from "react";
import './FeedbackPopup.css'
import TextArea from "antd/es/input/TextArea";

const FeedbackPopup = ({feedbackType, open, setOpen, messages, prompt, answer, index, feedback, setFeedback, mode})=>{
    const handleSubmitFeedback = ()=>{
        setOpen(false)
        if(mode == "consultant" || mode == "retrieval") {
            messages[index].feedback.detail = feedbackContent
            console.log(messages)
        } else if (mode == "reasoning" || mode == "drafting" || mode == "analysing") {
            setFeedback({type:feedbackType, detail:feedbackContent, prompt:prompt, answer:answer, mode:mode})
            console.log("feedback: ")
            console.log(
                {type:feedbackType, detail:feedbackContent, prompt:prompt, answer:answer, mode:mode}
            )
        } else {
            console.log('invalid mode parameter')
        }
    }

    const [feedbackContent, setFeedbackContent] = useState("")
    return(
    <div>
        <div className='mask-feedback'></div>
        {feedbackType == 'good'?
            <div className="popup-wrapper-feedback">
                {/* <Icon onClick={handleClosePopup} size='20' icon={CloseSvg} className="close-popup-btn"/> */}
                <div className="feedback-title">Provide Additional Feedback</div>
                <div className="feedback-box" ><TextArea className="feedback-box-content" style={{resize:'none'}}  placeholder="What do you like about the response" onChange={(msg)=>{setFeedbackContent(msg.target.value)}} value={feedbackContent}/></div>
                <button className="feedback-submit-button" onClick={handleSubmitFeedback}>Submit feedback</button>
                <button className="feedback-cancle-button" onClick={handleSubmitFeedback}>No, thanks</button>
            </div>:
            <div className="popup-wrapper-feedback">
                <div className="feedback-title">Provide Additional Feedback</div>
                <div className="feedback-box" ><TextArea className="feedback-box-content" style={{resize:'none'}}  placeholder="What is the issue with the response? How could it be improved?" onChange={(msg)=>{setFeedbackContent(msg.target.value)}} value={feedbackContent}/></div>
                <button className="feedback-submit-button" onClick={handleSubmitFeedback}>Submit feedback</button>
                <button className="feedback-cancle-button" onClick={handleSubmitFeedback}>No, thanks</button>
            </div>
        }
    </div>
    )
}

export default FeedbackPopup